<template>
  <v-container fluid grid-list-md class="px-0 pb-0">
    <v-row>
      <v-col cols="12">
        <div class="title-float accent--text">
          {{$tc('label.campos_gerais', 1)}}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-container fluid grid-list-md>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  id="descricao_campos_gerais"
                  v-model="passoGeral.descricao"
                  :rules="[rules.required]"
                  :disabled="somenteLeitura"
                  :label="`${$tc('label.descricao', 1)} *`"/>
              </v-col>
              <v-col cols="4" md="2" v-if="valorAcaoTemplateValorTotalAcao && !comMeta && !passoGeral.indAcaoAgrupadora">
                <input-money
                  :label="$tc('label.valor', 1)"
                  v-model="passoGeral.coeficienteValorTotal"
                  class="v-text-field"
                  @input="alteradoCoeficienteValorTotal()"
                  :disabled="somenteLeitura"/>
              </v-col>
              <v-col cols="4" md="2" v-if="valorAcaoTemplateValorTotalAcao && !passoGeral.indAcaoAgrupadora">
                <input-money
                  :label="`${$tc('label.valor_acao', 1)} *`"
                  v-model="passoGeral.valor"
                  :rules="[rules.required]"
                  class="v-text-field"
                  disabled/>
              </v-col>
              <v-col cols="12"
                     v-if="justificativa">
                <v-text-field
                  v-model="justificativa"
                  :label="`${$tc('label.justificativa', 1)}`"
                  :disabled="true"/>
              </v-col>
            </v-row>
            <metadados-container-layout
              v-if="parametrosAplicados"
              :metadados-entidade="metadadosParametrizado"
              :layout-class="layoutClass"
              :input-layout="inputLayout"
              :objeto="passoGeral"
              :campos-formulario="camposFormulario"
              :ordenacao-campos="ordenacaoFormulario"
              :explode-hierarquia="true"
              :somente-leitura="somenteLeitura"
              :exibe-loading-overlay="false"
              ref="container">
            </metadados-container-layout>
          </v-container>
          <v-card-text v-show="passoGeral.status === 'CANCELADO'">
            <span>
              {{ $t('message.justificativa_cancelamento_acao', {
                usuario: passoGeral.usuarioCancelamento,
                justificativa: passoGeral.justificativaCancelamento,
              }) }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import MetadadosContainerLayout from '../../../../shared-components/metadados/MetadadosContainerLayout';
import InputMoney from '../../../../shared-components/inputs/InputMoney';

export default {
  props: {
    alterarFinalizado: Boolean,
    copia: Boolean,
    novo: Boolean,
    passoGeral: Object,
    edicao: Boolean,
    somenteLeitura: Boolean,
    configuracaoAcao: Object,
    justificativa: String,
    camposLimitadores: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
      'getProdutoMetadado',
    ]),
    ordenacaoFormulario() {
      return this.delimitaCamposDeProduto();
    },
    comMeta() {
      return this.configuracaoAcao
        && this.configuracaoAcao.templateCalculo !== ''
        && this.configuracaoAcao.templateCalculo !== 'META_N/A';
    },
    valorAcaoTemplateValorTotalAcao() {
      const { configuracao } = this.configuracaoAcao;
      return configuracao.passo3.template === 'VALOR_TOTAL_ACAO';
    },
    isDetalheAcaoTemplate() {
      const { configuracao } = this.configuracaoAcao;
      return configuracao.passo3.template === 'DETALHE_ACAO';
    },
  },
  components: {
    MetadadosContainerLayout,
    InputMoney,
  },
  data() {
    return {
      produtoResource: this.$api.produto(this.$resource),

      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm6: true,
        md3: true,
      },

      parametrosAplicados: false,
      metadadosParametrizado: {},

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'valor',
            tipoCampo: 'DINHEIRO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'produto',
            nomCampoId: 'idProduto',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  ...this.$refs.container.getValoresDependencias(),
                };
                return this.produtoResource.selecao(parametros);
              },
              itemValue: 'id',
              itemText: 'nomProduto',
            },
          },
        ],
      },

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredFunction: (objLabel, valueLabel) => [
          () => {
            const objeto = this.passoGeral[objLabel] || {};
            const val = objeto[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return !!(val) || msg;
          },
        ],
      },
    };
  },
  methods: {
    delimitaCamposDeProduto() {
      if (this.contratoDefiniuCampoProduto()) {
        return this.camposSemProduto();
      }

      const amostragemCamposProduto = this.amostragemDeCamposDeProduto();
      const menorCampoContrato = this.menorCampoDeProdutoDoContrato();

      const { relacaoCampos } = this.configuracaoAcao;
      return relacaoCampos.filter((c) => {
        if (amostragemCamposProduto.indexOf(c) < 0) {
          return true;
        }

        const campoDaAcao = this.campoDeProdutoDaAcao(c);
        return campoDaAcao && campoDaAcao.indiceRecursao < menorCampoContrato.indiceRecursao;
      });
    },
    campoDeProdutoDaAcao(label) {
      const { mapaEntidades } = this.metadadosParametrizado;
      return mapaEntidades[label];
    },
    alteradoCoeficienteValorTotal() {
      this.$emit('PlanejamentoContratoFormAcoesCamposGerais__coeficienteCalculoAlterado',
        this.passoGeral.coeficienteValorTotal);
    },
    menorCampoDeProdutoDoContrato() {
      let menorCampo = { indiceRecursao: Number.MAX_SAFE_INTEGER };
      this.camposLimitadores.forEach((c) => {
        if (menorCampo.indiceRecursao > c.indiceRecursao) {
          menorCampo = c;
        }
      });
      return menorCampo;
    },
    camposSemProduto() {
      const { relacaoCampos } = this.configuracaoAcao;
      const amostragem = this.amostragemDeCamposDeProduto();
      return relacaoCampos
        .filter((c) => c !== 'produto' && amostragem.indexOf(c) < 0);
    },
    amostragemDeCamposDeProduto() {
      const { mapaEntidades } = this.getProdutoMetadado;
      return Object.values(mapaEntidades)
        .map((c) => c.label);
    },
    contratoDefiniuCampoProduto() {
      return this.camposLimitadores
        .filter((c) => c.label === 'produto').length > 0;
    },
    aplicaParametrizacoes() {
      this.metadadosParametrizado = this.configuracaoAcao
        .aplicaParametrizacao(this.getAcaoMetadado);
      this.configuracaoAcao
        .parametrizaCamposOpcionais(this.camposFormulario.padrao);
    },
    filtrarCamposFormulario() {
      if (this.passoGeral.indAcaoAgrupadora
        || (this.configuracaoAcao.valorAcaoCalculadoAutomaticamente
        && !this.isDetalheAcaoTemplate)) {
        this.camposFormulario.padrao = this.camposFormulario.padrao.filter((c) => c.labelCampo !== 'valor');
      }
    },
    configuraMetadadoCruzados() {
      const configuracoes = [
        {
          entidade: 'produto',
          metadadosCampo: this.getProdutoMetadado,
        },
      ];
      configuracoes.forEach((cfg) => {
        const campos = this.camposFormulario.padrao;
        const campo = campos.filter((c) => c.labelCampo === cfg.entidade)[0];
        campo.metadadosCampo = cfg.metadadosCampo;
      });
    },
    getObjetoFormatado() {
      const { idConfiguracao, idAcaoTipo } = this.configuracaoAcao;
      const {
        id,
        descricao,
        status,
        idAcaoAgrupadora,
        indAcaoAgrupadora,
      } = this.passoGeral;

      const objetoFormatado = {
        id,
        idAcaoTipo,
        idConfiguracao,
        descricao,
        status,
        idAcaoAgrupadora,
        indAcaoAgrupadora,
        ...this.$refs.container.getValoresCamposPadrao(),
      };

      if (!objetoFormatado.valor) {
        objetoFormatado.valor = this.passoGeral.valor;
      }
      if (this.passoGeral.coeficienteValorTotal) {
        objetoFormatado.coeficienteValorTotal = this.passoGeral.coeficienteValorTotal;
      }

      this.formataExtensoes(objetoFormatado);
      this.formataCamposDinamicos(objetoFormatado);

      return objetoFormatado;
    },
    formataExtensoes(objetoFormatado) {
      objetoFormatado.mapaExtensoes = {
        ...this.$refs.container.getValoresDependencias(),
      };
    },
    formataCamposDinamicos(objetoFormatado) {
      const camposDinamicos = this.$refs.container.getValoresCamposDinamicos();
      if (Object.keys(camposDinamicos).length !== 0) {
        objetoFormatado.mapaCamposDinamicos = { ...camposDinamicos };
      }
    },
  },
  mounted() {
    this.aplicaParametrizacoes();
    this.configuraMetadadoCruzados();
    this.filtrarCamposFormulario();
    this.parametrosAplicados = true;
  },
};
</script>
