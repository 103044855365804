<template>
  <v-dialog
    v-model="showModal"
    fullscreen
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable>
    <v-card tile v-if="carregouConfiguracao" style="background:#eee">
      <v-app-bar dark app color="primary" elevate-on-scroll clipped-right>
        <v-btn icon dark @click="fechaModal">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{$t('label.criacao_acao')}} :: {{tipoContrato}} :: {{tipoAcao}}
        </v-toolbar-title>
      </v-app-bar>

      <v-card-text style="flex: 1; margin-top: 5em;" class="pa-3">
        <v-form ref="form" lazy-validation v-if="acao != null" autocomplete="off">
          <div class="px-4">
            <planejamento-acao-form-resumo
              :exibe-cliente-pagador="exibeClientePagador"
              :objeto-passo-geral="objetoPassoGeralContrato"
              :ordenacao-formulario="ordenacaoFormularioContrato"
              :exibe-fornecedor-pagador="exibeFornecedorPagador"
              :somenteLeitura="true"
              ref="resumo">
              <div class="card-title" slot="titulo-acao">
                {{$tc('label.resumo_contrato', 1)}}
              </div>
            </planejamento-acao-form-resumo>
          </div>

          <planejamento-contrato-form-acoes-campos-gerais
            ref="camposGerais"
            :passo-geral="acao.passoGeral"
            :justificativa="acao.justificativa"
            :alterar-finalizado="alterarFinalizado"
            :copia="copia"
            :edicao="edicao"
            :novo="novo"
            :somente-leitura="acaoSomenteLeitura"
            :configuracao-acao="configuracaoAcao"
            :campos-limitadores="camposLimitadores"
            :configuracao-contrato="configuracaoContrato"
            @PlanejamentoContratoFormAcoesCamposGerais__coeficienteCalculoAlterado="coeficienteCalculoAlterado"
            />

          <planejamento-contrato-form-acoes-apuracao
            ref="camposApuracao"
            v-if="acao.passoComportamento"
            :passo-apuracao="acao.passoComportamento"
            :passo-geral="acao.passoGeral"
            :edicao="edicao"
            :alterar-finalizado="alterarFinalizado"
            :copia="copia"
            :novo="!acao.id"
            :somente-leitura="acaoSomenteLeitura"
            :configuracao-contrato="configuracaoContrato"
            :configuracao-acao="configuracaoAcao"
            />
        </v-form>
      </v-card-text>
      <v-card-actions style="max-height:50px">
        <v-spacer></v-spacer>
        <v-btn @click="fechaModal">{{ acaoSomenteLeitura ? $t('label.fechar') : $t('label.cancelar') }}</v-btn>
        <v-btn color="primary" @click="confirma" v-if="!acaoSomenteLeitura">{{ $t('label.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AcaoConfiguracao from '../../../planejamento-acao/AcaoConfiguracao';
import PlanejamentoAcaoFormResumo from '../../../planejamento-acao/form/PlanejamentoAcaoFormResumo';
import PlanejamentoContratoFormAcoesCamposGerais from './PlanejamentoContratoFormAcoesCamposGerais';
import PlanejamentoContratoFormAcoesApuracao from './PlanejamentoContratoFormAcoesApuracao';

export default {
  props: {
    alterarFinalizado: Boolean,
    copia: Boolean,
    edicao: Boolean,
    novo: Boolean,
    contratoEdicao: Object,
    novoContrato: Boolean,
    tipoContrato: String,
    somenteLeitura: Boolean,
    objetoPassoGeralContrato: Object,
    configuracaoContrato: Object,
    camposLimitadores: Array,
  },
  components: {
    PlanejamentoAcaoFormResumo,
    PlanejamentoContratoFormAcoesCamposGerais,
    PlanejamentoContratoFormAcoesApuracao,
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    acaoSomenteLeitura() {
      if (!this.acao) {
        return this.somenteLeitura;
      }
      const { passoGeral } = this.acao;
      const { status } = passoGeral;

      if (passoGeral.indAcaoAgrupadora
        || !this.permiteCadastrarContrato
        || ['AGUARDANDO_APROVACAO', 'APROVADO', 'CANCELADO', 'REPROVADO', 'EM_ANALISE'].includes(status)) {
        return true;
      }
      return false;
    },
    permiteCadastrarContrato() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'CONTRATO_CRUD').length;
    },
    tipoAcao() {
      return this.acao ? this.acao.nomeTipoAcao : '';
    },
    exibeClientePagador() {
      return this.configuracaoContrato.habilitaClientePagador;
    },
    exibeFornecedorPagador() {
      return this.configuracaoContrato.habilitaFornecedorPagador;
    },
    ordenacaoFormularioContrato() {
      const { relacaoCampos } = this.configuracaoContrato;
      return relacaoCampos;
    },
    carregouConfiguracao() {
      return this.showModal && this.configuracaoAcao !== null;
    },
  },
  data() {
    return {
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),
      acao: null,
      configuracaoAcao: null,

      showModal: false,
      modalCallback: null,
      callbackValidation: null,
    };
  },
  methods: {
    abreForm(acao, callback, callbackValidation) {
      this.acao = { ...acao };
      this.acao.passoGeral = { ...acao.passoGeral };
      this.acao.passoComportamento = { ...acao.passoComportamento };
      this.modalCallback = callback;
      this.callbackValidation = callbackValidation;
      this.showModal = true;

      this.carregarConfiguracao()
        .then(() => {
          if (this.$refs.resumo) {
            this.$refs.resumo.montaResumo();
          }
        });
    },
    novaAcao(acao, callback, callbackValidation) {
      if (acao.idAcaoTipo) {
        this.showModal = true;
        this.acao = this.criaCamposIniciaisAcao(acao);
        this.modalCallback = callback;
        this.callbackValidation = callbackValidation;

        this.carregarConfiguracao()
          .then(() => {
            if (this.$refs.resumo) {
              this.$refs.resumo.montaResumo();
            }
          });
      }
    },
    criaCamposIniciaisAcao(acao) {
      return {
        ...acao,
        passoGeral: {
          mapaExtensoes: {},
        },
        passoComportamento: {
          periodosApuracao: null,
          baseCalculo: null,
          formaBonificacao: null,
          tipoPagamento: null,
          calculoBonificacao: null,
          calculoApuracao: null,
          unidadeMedida: null,
          dtaInicio: null,
          dtaFim: null,
          metas: [],
          descontos: [],
        },
      };
    },
    validaFormulario() {
      let v = this.$refs.form.validate();
      v = v && this.$refs.camposApuracao.validarMetas();
      v = v && this.$refs.camposApuracao.validarDatas();
      return v;
    },
    confirma() {
      if (!this.validaFormulario()) {
        return;
      }

      if (this.callbackValidation && !this.callbackValidation(this.acao)) {
        return;
      }

      this.modalCallback({
        acao: this.acao,
        acaoFormatada: this.formataAcao(),
      });

      this.fechaModal();
    },
    fechaModal() {
      this.showModal = false;
      this.configuracaoAcao = null;
      this.acao = null;
      this.modalCallback = null;

      this.$refs.form.resetValidation();
    },
    carregarConfiguracao() {
      const { idAcaoTipo, id } = this.acao;

      const promise = id !== undefined
        ? this.configuracaoResource.buscarConfigSnapshot({ idAcao: id })
        : this.configuracaoResource.buscarConfigVigente({ idTipoAcao: idAcaoTipo });

      return promise.then((res) => {
        this.configuracaoAcao = new AcaoConfiguracao(res.data);
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    getDatas() {
      const passoComportamento = this.$refs.camposApuracao.getObjetoFormatado();
      const dtaInicio = passoComportamento.dtaInicio || null;
      const dtaFim = passoComportamento.dtaFim || null;

      return {
        dtaInicio,
        dtaFim,
      };
    },
    carregaMapaExtensoes(objetoFormatado) {
      const objeto = {};
      objeto.mapaExtensoes = { ...objetoFormatado.mapaExtensoes };
      this.configuracaoContrato.extensoesCarteira.forEach((e) => {
        objeto.mapaExtensoes[`id_${e.label}`] = this.objetoPassoGeralContrato[e.label].id;
      });
      return objeto;
    },
    coeficienteCalculoAlterado(valor) {
      if (this.$refs.camposApuracao) {
        this.$refs.camposApuracao.coeficienteCalculoAlterado(valor);
      }
    },
    formataAcao() {
      const objetoFormatado = { ...this.$refs.camposGerais.getObjetoFormatado() };
      const passoGeral = {
        ...objetoFormatado,
        ...this.getDatas(),
        ...this.carregaMapaExtensoes(objetoFormatado),
      };
      const passoComportamento = this.$refs.camposApuracao.getObjetoFormatado();

      const { id } = this.acao;

      const acao = {
        id,
        passoGeral,
        passoComportamento,
      };

      return acao;
    },
  },
};
</script>
