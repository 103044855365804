<template>
  <div>
    <v-container
      fluid>
      <v-data-iterator
        :items="acoes"
        :search="filtro"
        :hide-default-footer="true"
        :disable-pagination="true"
        :custom-filter="filtrarCards">
        <template v-slot:default="props">
          <v-row class="pl-2">
            <v-col cols="12" v-for="(acao, index) in  props.items"
              :key="index"
              sm="6"
              lg="3">
              <v-hover>
                <v-card
                  class="mx-auto"
                  style="border-radius: 6px"
                  slot-scope="{ hover }"
                  :class="`elevation-${hover ? 6 : 2}`">
                  <v-card-title>
                    <span>{{ acao.nomeTipoAcao }}</span>
                    <v-spacer />
                    <status
                        :status="acao.passoGeral.status || 'EM_CADASTRO'"
                        :tooltip="acao.passoGeral.status || 'EM_CADASTRO'"/>
                  </v-card-title>
                  <v-card-text class="font-weight-light title">
                    {{ acao.passoGeral.descricao }}
                  </v-card-text>
                  <v-card-actions>
                    <v-list-item class="grow">
                      <v-row no-gutters>
                        <v-col>
                          <v-list-item-content class="PlanejamentoContratoFormAcoesControleCard__color">
                            <v-list-item-title>{{ montarData(acao) }}</v-list-item-title>
                          </v-list-item-content>
                        </v-col>
                        <v-col>
                          <v-row align="center" justify="end" right>
                            <div>
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    text
                                    icon
                                    v-if="podeClonar(acao)"
                                    @click="copiaAcao(acao.index)"
                                    v-on="on"
                                    color="rgba(0,0,0,.54)"
                                    class="PlanejamentoContratoFormAcoesControleCard__btn"
                                    >
                                    <v-icon>library_add</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  {{$t('label.copiar_acao')}}
                                </span>
                              </v-tooltip>
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    text
                                    icon
                                    v-if="podeExcluir(acao) && permiteCadastrarContrato"
                                    @click="confirmaRemoverAcao(acao.index)"
                                    v-on="on"
                                    color="rgba(0,0,0,.54)"
                                    class="PlanejamentoContratoFormAcoesControleCard__btn">
                                    <v-icon>remove_circle</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  {{$t('label.excluir_a_acao')}}
                                </span>
                              </v-tooltip>
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    text
                                    icon
                                    @click="editaVisualizaAcao(acao, acao.index)"
                                    v-on="on"
                                    color="rgba(0,0,0,.54)"
                                    class="PlanejamentoContratoFormAcoesControleCard__btn">
                                    <v-icon v-if="permiteEditar(acao) && permiteCadastrarContrato">edit</v-icon>
                                    <v-icon v-else>visibility</v-icon>
                                  </v-btn>
                                </template>
                                <span v-if="permiteEditar(acao) && permiteCadastrarContrato">
                                  {{$t('label.editar_a_acao')}}
                                </span>
                                <span v-else>
                                  {{$tc('label.detalhe', 2)}}
                                </span>
                              </v-tooltip>
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    text
                                    icon
                                    v-if="podeCancelar(acao)"
                                    @click="confirmaCancelarAcao(acao.index)"
                                    v-on="on"
                                    color="rgba(0,0,0,.54)"
                                    class="PlanejamentoContratoFormAcoesControleCard__btn">
                                    <v-icon>clear</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  {{$t('label.cancelar')}}
                                </span>
                              </v-tooltip>
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    text
                                    icon
                                    v-if="podeCancelarFluxo(acao)"
                                    @click="confirmaCancelarFluxo(acao.index)"
                                    v-on="on"
                                    color="rgba(0,0,0,.54)"
                                    class="PlanejamentoContratoFormAcoesControleCard__btn">
                                    <v-icon>clear</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  {{$t('label.cancelar')}}
                                </span>
                              </v-tooltip>
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    text
                                    icon
                                    v-if="permiteSolicitarAprovacao(acao)
                                     && permiteCadastrarContrato
                                      && usuarioPodeSolicitarEsseTipoAcao(acao)"
                                    @click="solicitarAprovacao(acao)"
                                    v-on="on"
                                    color="rgba(0,0,0,.54)"
                                    class="PlanejamentoContratoFormAcoesControleCard__btn">
                                    <v-icon>send</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  {{$t('label.solicitar_aprovacao')}}
                                </span>
                              </v-tooltip>
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    text
                                    icon
                                    v-if="acaoEmAnalise(acao)
                                     && permiteCadastrarContrato
                                      && !temFluxoContrato
                                      && !acaoAgrupadora(acao)"
                                    @click="confirmarExecutarAnalise(acao.index)"
                                    v-on="on"
                                    color="rgba(0,0,0,.54)"
                                    class="PlanejamentoContratoFormAcoesControleCard__btn">
                                    <v-icon>edit</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  {{$tc('label.executar_analise', 1)}}
                                </span>
                              </v-tooltip>
                            </div>
                            <div v-if="!acaoAgrupadora(acao)">
                              <planejamento-acao-fluxo
                                  :ref="`acaoFluxo${acao.index}`"
                                  :key="acao.id"
                                  :idAcao="acao.id"
                                  :acao-de-contrato="true"
                                  :status="acao.passoGeral.status"
                                  :exibir-justificativa="!temFluxoContrato"
                                  :exibir-justificativa-em-cadastro="acao.passoGeral.temFluxo"
                                  @recarregar="recarregarDados(acao.id, acao.index)"
                                  :exibir-acompanhamento="acao.passoGeral.temFluxo"
                                  @PlanejamentoAcaoFluxoFuncoes__justificativa
                                      ="(j) => preencherJustificativa(acao, j)"/>
                            </div>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-list-item>
                  </v-card-actions>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
    <confirm
      ref="mensagensAvisoDialog"
      :message="mensagemAviso"
      :persistent="true"
      hide-disagree
      :agree-label="$t('label.ok')"/>
    <confirm
      ref="removerAcaoDialog"
      :message="msgRemocaoAcao"
      :persistent="true"
      @agree="removeAcao"/>
    <confirm
      ref="executarAnaliseDialog"
      :message="msgExecutarAnalise"
      :persistent="true"
      @agree="executarAnalise"/>
    <confirm
      ref="fluxoSemUsuarioClienteDialog"
      :message="msgFluxoSemAprovador"
      :persistent="true"
      @agree="iniciaFluxo"/>
    <planejamento-acao-form-usuario-cliente
      ref="modalUsuarioCliente"
      :title="indModoVarejo ? $tc('title.selecao_aprovador_fornecedor', 1) : $tc('title.selecao_aprovador_cliente', 1)"
      :passoCliente="passoCliente"
      @selecionarUsuarioCliente="selecionaAprovadorPassoClienteFornecedor"/>
    <planejamento-contrato-dialog-cancelar-acao
      ref="cancelarAcaoDialog"
      :cancelarAcao="funcCancelar"
      :mensagem="mensagemCancelar"/>
    <planejamento-contrato-form-acoes-apuracoes-agrupadas
      ref="modalApuracoesAgrupadas"
      @SOLICITAR_APROVACAO="executaSolicitarAprovacao"
      @ERRO_CONFLITO_ACAO_AGRUPADORA="exibirErroConflitoAcaoAgrupadora"/>
  </div>
</template>
<script>
import PlanejamentoAcaoFluxo from '@/spa/planejamento-acao/PlanejamentoAcaoFluxo';
import Status from '../../../../shared-components/Status';
import Confirm from '../../../../shared-components/vuetify/dialog/Confirm';
import PlanejamentoContratoFormAcoesControleItens from './PlanejamentoContratoFormAcoesControleItens';
import PlanejamentoContratoDialogCancelarAcao from './PlanejamentoContratoDialogCancelarAcao';
import PlanejamentoAcaoFormUsuarioCliente from '../../../planejamento-acao/form/PlanejamentoAcaoFormUsuarioCliente';
import PlanejamentoContratoFormAcoesApuracoesAgrupadas from './PlanejamentoContratoFormAcoesApuracoesAgrupadas';

export default {
  props: {
    acoes: {
      type: Array,
      required: true,
    },
    acoesFormatadas: {
      type: Array,
      required: true,
    },
    tiposAcaoSolicitaveis: {
      type: Array,
      required: true,
    },
    somenteLeitura: Boolean,
  },
  mixins: [PlanejamentoContratoFormAcoesControleItens],
  components: {
    PlanejamentoAcaoFormUsuarioCliente,
    Confirm,
    Status,
    PlanejamentoContratoDialogCancelarAcao,
    PlanejamentoAcaoFluxo,
    PlanejamentoContratoFormAcoesApuracoesAgrupadas,
  },
  watch: {
    acoes() {
      this.defineIndicesGlobais();
    },
  },
  methods: {
    forcaAtualizacao() {
      const old = this.filtro;
      this.filtro = `${this.filtro} `;
      this.$forceUpdate();
      setTimeout(() => { this.filtro = old; });
    },
    filtrarCards(acoes, filtro) {
      return acoes.filter((acao) => acao.passoGeral.descricao
        .toUpperCase()
        .indexOf(filtro.toUpperCase()) >= 0
          || acao.nomeTipoAcao
            .toUpperCase()
            .indexOf(filtro.toUpperCase()) >= 0);
    },
    usuarioPodeSolicitarEsseTipoAcao(acao) {
      return this.tiposAcaoSolicitaveis.includes(acao.nomeTipoAcao);
    },
  },
  beforeMount() {
    this.defineIndicesGlobais();
  },
};
</script>

<style scoped>
.PlanejamentoContratoFormAcoesControleCard__color {
  color: rgba(0,0,0,.54);
  font-size: 0,85rem;
}
</style>
